import React from "react";
import { Container, Duration, Hour } from "./index.style";
import { ArrowRightIcon, Flex, Status, Text } from "@fluentui/react-northstar";
import { CONFIG_SLOT } from "./constants";
import { useTranslation } from "react-i18next";
import { I18N_NS_TIME_REPORTING } from "../../constants";
import { HomeFilled } from "@fluentui/react-icons";
import Break from "../Break";
import moment from "moment";

/**
 * @typedef {Object} PropType
 * @property {("default"|"saved_or_sent"|"validated"|"clocktime_start"|"clocktime_paused")} status
 * @property {string} start_time - the start time format 00:00:00
 * @property {string} end_time - the end time format 00:00:00
 * @property {string} duration
 * @property {boolean} [isSlotsTimeClock]
 * @property {boolean} [isPaused]
 * @property {boolean} [isOnsite]
 * @property {boolean} [isLastItem]
 *
 */

/**
 * Slot permet d'afficher un créneau
 * @param {PropType} PropType
 * @returns {ReactNode}
 */
const Slot = ({
    status,
    start_time,
    end_time,
    duration,
    isSlotsTimeClock = false,
    isPaused = false,
    isOnsite = false,
    isLastItemBreak = false,
}) => {
    const { color, fontColor } = CONFIG_SLOT[status] ?? CONFIG_SLOT.default;
    const { t } = useTranslation(I18N_NS_TIME_REPORTING);

    const format_start_time = moment(start_time, "HH:mm:ss").format("HH:mm");
    const format_end_time = moment(end_time, "HH:mm:ss").format("HH:mm");

    return (
        <Container
            bg={color}
            hAlign="center"
            vAlign="center"
            column
            styles={{
                marginBottom: isSlotsTimeClock ? "16px" : "auto",
            }}
        >
            {isSlotsTimeClock && isPaused && isLastItemBreak ? (
                <Flex vAlign="center">
                    <Break duration={duration} isSlotsTimeClock={true} />
                </Flex>
            ) : (
                <>
                    <Flex space="between" vAlign="center" fill>
                        <Hour
                            content={format_start_time}
                            fontColor={fontColor}
                        />
                        <ArrowRightIcon size="smaller" />
                        {/* afficher le en cours */}
                        {isSlotsTimeClock && !end_time && !isLastItemBreak ? (
                            <Flex vAlign="center">
                                <Status
                                    state="success"
                                    title="success"
                                    size="smallest"
                                    styles={{
                                        marginInline: "2px",
                                    }}
                                />
                                <Hour content="En cours" />
                            </Flex>
                        ) : (
                            <Hour
                                content={format_end_time}
                                fontColor={fontColor}
                            />
                        )}
                    </Flex>
                    <Flex gap="gap.smaller">
                        {isSlotsTimeClock && isOnsite && (
                            <Flex
                                styles={{
                                    borderRadius: "100px",
                                    background: isPaused
                                        ? "#C7E0F4"
                                        : "#2B88D8",
                                    padding: "2px 6px",
                                }}
                                vAlign="center"
                                hAlign="center"
                            >
                                <HomeFilled color="white" />
                            </Flex>
                        )}
                        <Duration
                            content={`${t(
                                "v2.home.pills.duration"
                            )} : ${duration}`}
                        />
                    </Flex>
                </>
            )}
        </Container>
    );
};

export default Slot;
