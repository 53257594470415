import moment from "moment";
import { getYearMonthOfThisWeek } from "../functions";

const { month, year } = getYearMonthOfThisWeek();

export const CURRENT_MONTH_NAME = moment().month("MMMM").format("MMMM");
export const CURRENT_MONTH_INDEX = month;
export const CURRENT_YEAR = year;
export const CURRENT_WEEK = moment().isoWeek();

export const ACTION_TYPES = {
    increment_week: "increment_week",
    decrement_week: "decrement_week",
    toggle_mode_editable: "toggle_mode_editable",
    toggle_weekend: "toggle_weekend",
    mode_update_week_template: "mode_update_week_template",
    goto_week: "goto_week",
};
export const INIT_REDUCER_WEEK = {
    iso_week: CURRENT_WEEK,
    year: CURRENT_YEAR,
    month_indexed: CURRENT_MONTH_INDEX,
    dates: {
        start: moment().isoWeekday(1).format("DD MMMM YYYY"),
        end: moment().isoWeekday(7).format("DD MMMM YYYY"),
    },
    start_date_week: moment().isoWeekday(1).format("YYYY-MM-DD"),
    mode_editable_activated: false,
    mode_update_week_template: false,
    toggle_weekend: false,
};

export const reducer = (state, action) => {
    switch (action.type) {
        case ACTION_TYPES.goto_week: {
            // Utiliser les données du payload ou les valeurs du state actuel
            const m = moment(state.start_date_week, "YYYY-MM-DD"); // Utiliser la date de début actuelle

            // Appliquer la semaine et l'année passées dans l'action
            m.isoWeekYear(action.payload?.year ?? state.year) // Fixer l'année ISO
                .isoWeek(action.payload.week); // Fixer la semaine ISO

            // Calculer le premier jour de la semaine (lundi)
            const month_iso = m.isoWeekday(1); // Lundi de la semaine
            const start_date_week = month_iso.format("YYYY-MM-DD"); // Date de début de la semaine
            const start = month_iso.format("DD MMMM YYYY"); // Affichage de la date de début
            const end = m.isoWeekday(7).format("DD MMMM YYYY"); // Dimanche de la semaine
            const iso_week = m.isoWeek(); // Semaine ISO actuelle
            const year = month_iso.year(); // Année ISO correcte
            const month = m.month(); // Mois basé sur la semaine

            return {
                ...state,
                dates: {
                    start,
                    end,
                },
                iso_week: iso_week, // Semaine ISO
                year, // Année ISO correcte
                month_indexed: month, // Mois 0-indexé
                start_date_week,
                mode_editable_activated: false,
            };
        }

        case ACTION_TYPES.increment_week: {
            // Initialiser un objet moment basé sur les valeurs actuelles du state
            const m = moment(state.start_date_week, "YYYY-MM-DD"); // Utiliser la date de début actuelle pour la transition

            // Incrémenter d'une semaine
            m.add(1, "week");

            // Calculer les nouvelles dates
            const start_date_week = m.format("YYYY-MM-DD"); // Lundi de la nouvelle semaine
            const start = m.format("DD MMMM YYYY");
            const end = m.clone().isoWeekday(7).format("DD MMMM YYYY"); // Dimanche de la nouvelle semaine
            const iso_week = m.isoWeek(); // Nouvelle semaine ISO
            const year = m.isoWeekYear(); // Année correcte basée sur la norme ISO
            const month_indexed = m.month(); // Mois 0-indexé basé sur la date de début

            return {
                ...state,
                dates: {
                    start,
                    end,
                },
                iso_week,
                year,
                month_indexed,
                start_date_week,
                mode_editable_activated: false,
            };
        }

        case ACTION_TYPES.decrement_week: {
            // Initialiser un objet moment basé sur les valeurs actuelles du state
            const m = moment(state.start_date_week, "YYYY-MM-DD"); // Utiliser la date de début actuelle pour la transition

            // Décrémenter d'une semaine
            m.subtract(1, "week");

            // Calculer les nouvelles dates
            const start_date_week = m.format("YYYY-MM-DD"); // Lundi de la nouvelle semaine
            const start = m.format("DD MMMM YYYY");
            const end = m.clone().isoWeekday(7).format("DD MMMM YYYY"); // Dimanche de la nouvelle semaine
            const iso_week = m.isoWeek(); // Nouvelle semaine ISO
            const year = m.isoWeekYear(); // Année correcte basée sur la norme ISO
            const month_indexed = m.month(); // Mois 0-indexé basé sur la date de début

            return {
                ...state,
                dates: {
                    start,
                    end,
                },
                iso_week,
                year,
                month_indexed,
                start_date_week,
                mode_editable_activated: false,
            };
        }

        case ACTION_TYPES.toggle_mode_editable: {
            return {
                ...state,
                mode_editable_activated: action.payload.value,
            };
        }
        case ACTION_TYPES.toggle_weekend: {
            return {
                ...state,
                toggle_weekend: action.payload.value,
            };
        }
        case ACTION_TYPES.mode_update_week_template: {
            return {
                ...state,
                mode_update_week_template: action.payload.value,
            };
        }

        default:
            break;
    }
};
