import React from "react";
import { BreakText, Container } from "./index.style";
import { useTranslation } from "react-i18next";
import { I18N_NS_TIME_REPORTING } from "../../constants";
import { Flex, Status } from "@fluentui/react-northstar";
import { Hour } from "../Slot/index.style";

const Break = ({ duration, isSlotsTimeClock = false, is_paused = false }) => {
    const { t } = useTranslation(I18N_NS_TIME_REPORTING);
    return (
        <Container
            hAlign="center"
            vAlign="center"
            styles={{
                marginBottom: isSlotsTimeClock ? "16px" : "auto",
            }}
        >
            <BreakText
                content={`${t("v2.home.pills.break")} : ${duration ?? ""}`}
            />
            {is_paused && (
                <Flex vAlign="center">
                    <Status
                        state="success"
                        title="success"
                        size="smallest"
                        styles={{
                            marginInline: "2px",
                        }}
                    />
                    <Hour content="En cours" weight={400} />
                </Flex>
            )}
        </Container>
    );
};

export default Break;
