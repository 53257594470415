import React, { useEffect, useState } from "react";
import {
    ChatIcon,
    Flex,
    Text as TextTeams,
    Attachment,
    DownloadIcon,
    Text,
    Divider,
    Avatar,
    ArrowRightIcon,
    Button,
    EyeIcon,
    Tooltip,
    InfoIcon,
} from "@fluentui/react-northstar";
import styled from "styled-components";
import DetailModalTasks from "../../components/tasks/DetailModalTasks";
import { DEVICE } from "../../constants/responsive";
import {
    BADGE_STATUS_TASKS,
    render_badge_action_document_by_id,
    TASK_STATUS,
} from "../../constants/tasks";
import { ICON_FROM_EXTENSION } from "../../utils/functions";
import { _api_download_file } from "../../api/manage_document";
import CustomDropzone from "../../components/dropzone";
import moment from "moment";
import { file_size_format } from "../../constants/maths";
import { AttachmentStyle } from "./style";
import { useMediaQuery } from "react-responsive";
// BADGE_ACTION_DOCUMENTS
const ModalDetailTask = ({
    data,
    savedFiles,
    setSavedFiles,
    hideCreationAndValidation = true,
    isHrPage = false,
    isPreviewDocument = false,
    documentToPreview = null,
    isPremiumPspdfkit = false,
    onResetPspdfkit = () => console.log("je vais reset le pspdfkit"),
    onClickToPreview = () => console.log("preview this file"),
    onUploadPspdfkitSuccess = () =>
        console.log("bien upload le document pspdfkit"),
}) => {
    const isExpired =
        data?.status === TASK_STATUS.TODO &&
        moment(data?.due_date).isBefore(moment(), "day");

    const isMobile = useMediaQuery({
        query: "(max-width: 500px)",
    });

    const onUploadSuccess = (file) => {
        if (!file?.pspdfkit_document_id) {
            return console.log("Y'a pas de document id");
        }
        return onUploadPspdfkitSuccess(file);
    };

    return (
        <DetailModalTasks>
            <Flex vAlign="center" styles={{ marginTop: "15px" }}>
                <Text
                    style={{
                        backgroundColor: "#292929",
                        fontSize: "10px",
                        fontWeight: "normal",
                        color: "white",
                        padding: "2px 6px",
                        borderRadius: "100px",
                    }}
                >
                    {data.category?.name}
                </Text>
            </Flex>
            <Flex gap="gap.smaller" column>
                <FlexRowBadge
                    style={{ flexWrap: "wrap" }}
                    fill
                    vAlign="center"
                    gap="gap.large"
                    space="between"
                >
                    <Flex.Item>
                        <DetailModalTasks.TaskType
                            content={BADGE_STATUS_TASKS[data?.status]}
                        />
                    </Flex.Item>
                    <Flex.Item>
                        {render_badge_action_document_by_id(
                            data?.document_action
                        )}
                    </Flex.Item>
                    {!isMobile && (
                        <Flex
                            styles={{
                                whiteSpace: "nowrap",
                            }}
                        >
                            <DetailModalTasks.BolderLabel
                                label="Echéance :"
                                value={moment(data.due_date).format(
                                    "DD/MM/YYYY"
                                )}
                                danger={isExpired}
                            />
                        </Flex>
                    )}
                </FlexRowBadge>
                {isMobile && (
                    <Flex
                        styles={{
                            whiteSpace: "nowrap",
                        }}
                    >
                        <DetailModalTasks.BolderLabel
                            label="Echéance :"
                            value={moment(data.due_date).format("DD/MM/YYYY")}
                            danger={isExpired}
                        />
                    </Flex>
                )}
            </Flex>
            <Flex fill grow vAlign="center" column>
                <DetailModalTasks.BolderLabel label="Descriptif :" value={""} />
                <DescriptionText content={data.description} />
            </Flex>
            {!hideCreationAndValidation && (
                <FlexCreationValidation
                    gap="gap.large"
                    style={{ marginBottom: "16px" }}
                >
                    <Flex column>
                        <DetailModalTasks.BolderLabel
                            label="Création :"
                            value={moment(data?.created_at).format(
                                "DD/MM/YYYY"
                            )}
                        />
                        <Flex column>
                            <Flex
                                grow
                                gap="gap.smaller"
                                justifyContent="center"
                                vAlign="center"
                                styles={{
                                    marginTop: "6px",
                                }}
                            >
                                <Flex.Item>
                                    <Avatar
                                        name={data?.creator?.name}
                                        image={data?.creator?.avatar}
                                        size="smaller"
                                    />
                                </Flex.Item>
                                <Flex.Item>
                                    <TextTeams
                                        as="a"
                                        href={`MSTeams:/l/chat/0/0?users=${data.creator?.email}`}
                                        color="brand"
                                        content={data.creator?.name}
                                        weight="semibold"
                                        styles={{
                                            outline: "0",
                                        }}
                                    />
                                </Flex.Item>
                            </Flex>
                        </Flex>
                    </Flex>
                    {data?.task_to_validate && (
                        <Flex column>
                            <DetailModalTasks.BolderLabel
                                label="Validation :"
                                value={""}
                                breakLine
                            />
                            <Flex column>
                                <Flex
                                    grow
                                    gap="gap.smaller"
                                    justifyContent="center"
                                    vAlign="center"
                                    styles={{
                                        marginTop: "6px",
                                    }}
                                >
                                    <Flex.Item>
                                        <Avatar
                                            name={data.creator?.name}
                                            image={data.creator?.avatar}
                                            size="smaller"
                                        />
                                    </Flex.Item>
                                    <Flex.Item>
                                        <TextTeams
                                            as="a"
                                            href={`MSTeams:/l/chat/0/0?users=${data.creator?.email}`}
                                            color="brand"
                                            content={data.creator?.name}
                                            weight="semibold"
                                            styles={{
                                                outline: "0",
                                            }}
                                        />
                                    </Flex.Item>
                                </Flex>
                            </Flex>
                        </Flex>
                    )}
                </FlexCreationValidation>
            )}
            {/* documents parts */}
            {data?.documents_template?.length > 0 && (
                <Flex vAlign="center" gap="gap.small" column>
                    <DetailModalTasks.BolderLabel
                        label="Document original"
                        value={""}
                    />
                    {data?.documents_template?.map((el) => {
                        const isHightlighted =
                            documentToPreview === el?.attached_doc;
                        return (
                            <AttachmentStyle
                                header={
                                    <Text truncated content={el?.filename} />
                                }
                                description={file_size_format(el.filesize)}
                                icon={ICON_FROM_EXTENSION(el.filename)}
                                actionable
                                action={{
                                    icon: isPreviewDocument ? (
                                        <EyeIcon
                                            styles={{
                                                color: "var(--color-primary)",
                                            }}
                                        />
                                    ) : (
                                        <DownloadIcon />
                                    ),
                                    onClick: (e) => {
                                        console.log({ e });
                                        e.stopPropagation();
                                        if (isPreviewDocument) {
                                            onClickToPreview(el);
                                        } else {
                                            return _api_download_file({
                                                id: el.id,
                                                filename: el.filename,
                                            });
                                        }
                                    },
                                    title: isPreviewDocument
                                        ? "Preview"
                                        : "download",
                                }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    if (isPreviewDocument) {
                                        onClickToPreview(el);
                                    } else {
                                        window.open(el.attached_doc);
                                    }
                                }}
                                styles={{
                                    border: isHightlighted
                                        ? "1px solid #9299F7"
                                        : "none",
                                }}
                            />
                        );
                    })}
                </Flex>
            )}
            {/* if the task contain documents => show it */}
            {data?.document_required && data.documents?.length > 0 && (
                <>
                    <div style={{ marginBlock: "15px" }}>
                        <Divider />
                    </div>
                    <Flex column gap="gap.small">
                        <Text styles={{ fontWeight: "600" }}>
                            {data?.documents.length > 0 ? (
                                <DetailModalTasks.BolderLabel
                                    label="Document envoyé"
                                    value={""}
                                />
                            ) : (
                                ""
                            )}
                        </Text>
                        {data?.documents?.map((el) => {
                            console.log({ el });
                            const isHightlighted =
                                documentToPreview === el?.attached_doc;
                            return (
                                <AttachmentStyle
                                    header={
                                        <Text truncated content={el?.name} />
                                    }
                                    description={file_size_format(el.filesize)}
                                    icon={ICON_FROM_EXTENSION(el.name)}
                                    actionable
                                    action={{
                                        icon: isPreviewDocument ? (
                                            <EyeIcon
                                                styles={{
                                                    color: "var(--color-primary)",
                                                }}
                                            />
                                        ) : (
                                            <DownloadIcon />
                                        ),
                                        onClick: (e) => {
                                            e.stopPropagation();
                                            if (isPreviewDocument) {
                                                onClickToPreview(el);
                                            } else {
                                                return _api_download_file({
                                                    id: el.id,
                                                    filename: el.filename,
                                                });
                                            }
                                        },
                                        title: isPreviewDocument
                                            ? "Preview"
                                            : "download",
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        if (isPreviewDocument) {
                                            onClickToPreview(el);
                                        } else {
                                            window.open(el.attached_doc);
                                        }
                                    }}
                                    styles={{
                                        border: isHightlighted
                                            ? "1px solid #9299F7"
                                            : "none",
                                    }}
                                />
                            );
                        })}
                    </Flex>
                </>
            )}
            {/* if the task is required document then show dropzone */}
            {data?.document_required &&
                data.status !== TASK_STATUS.DONE &&
                data.status !== TASK_STATUS.TO_APPROVE &&
                !isHrPage && (
                    <Flex column gap="gap.small">
                        {savedFiles.length > 0 ? (
                            <Flex vAlign="center" space="between" fill>
                                <LabelDocument>
                                    <Flex>
                                        <DetailModalTasks.BolderLabel
                                            label="Document à envoyer"
                                            value={""}
                                        />
                                        <Tooltip
                                            trigger={<InfoIcon />}
                                            content="Charger de document"
                                        />
                                    </Flex>
                                </LabelDocument>
                                <Button
                                    text
                                    flat
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setSavedFiles([]);
                                        onResetPspdfkit();
                                    }}
                                    styles={{
                                        textDecoration: "underline",
                                        color: "var(--color-primary)",
                                    }}
                                >
                                    Changer de document
                                </Button>
                            </Flex>
                        ) : data?.document_action ===
                          4 ? null : isPremiumPspdfkit ? null : (
                            <>
                                <div style={{ marginBlock: "15px" }}>
                                    <Divider />
                                </div>
                                <LabelDocument>
                                    <DetailModalTasks.BolderLabel
                                        label="Document à envoyer"
                                        value={""}
                                    />
                                </LabelDocument>
                            </>
                        )}
                        {/* ne pas afficher la DZ sauf si c'est pas PREMIUM */}
                        {!isPremiumPspdfkit && (
                            <CustomDropzone
                                savedFiles={savedFiles}
                                setSavedFiles={setSavedFiles}
                                showInformationFile={false}
                                oneFile={true}
                                maxFiles={1}
                                size_limit={2_500_000}
                                showAttachmentActionsButton={
                                    data.status === TASK_STATUS.TO_APPROVE ||
                                    data.status === TASK_STATUS.DONE
                                        ? false
                                        : true
                                }
                                documentToPreview={documentToPreview}
                                isPreviewDocument={true}
                                onClickToPreview={(data) => {
                                    return onClickToPreview({
                                        ...data,
                                        attached_doc: data?.uploaded_doc,
                                    });
                                }}
                                onUploadSuccess={onUploadSuccess}
                            />
                        )}
                    </Flex>
                )}
        </DetailModalTasks>
    );
};

export default ModalDetailTask;

const LabelDocument = styled(Text)`
    color: #242424;
    font-size: 18px;
    font-style: normal;
    line-height: 24px; /* 133.333% */
`;

const LabelThin = styled(Text)`
    color: #424242;
    font-size: 14px;
    font-style: normal;
    font-weight: 350;
    line-height: 20px; /* 142.857% */
`;
const DescriptionText = styled(Text)`
    color: #424242;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
`;

const FlexItemEcheanceMobile = styled(Flex)`
    @media ${DEVICE.mobileS} {
        display: flex;
    }
    @media ${DEVICE.mobileM} {
        display: flex;
    }
    @media ${DEVICE.mobileL} {
        display: flex;
    }
    @media ${DEVICE.tablet} {
        display: none;
    }
    @media ${DEVICE.laptop} {
        display: none;
    }
    @media ${DEVICE.desktop} {
        display: none;
    }
    @media ${DEVICE.desktopL} {
        display: none;
    }
`;

const FlexCreationValidation = styled(Flex)`
    @media ${DEVICE.mobileS} {
        flex-direction: column;
        gap: 20px;
    }
    @media ${DEVICE.mobileM} {
        flex-direction: column;
        gap: 20px;
    }
    @media ${DEVICE.mobileL} {
        flex-direction: column;
        gap: 20px;
    }
    @media ${DEVICE.laptop} {
        flex-direction: row;
        gap: 20px;
        margin-bottom: 24px !important;
        margin-top: 18px !important;
    }
`;

const FlexRowBadge = styled(Flex)`
    @media ${DEVICE.mobileS} {
        margin-top: 0px;
    }
    @media ${DEVICE.mobileM} {
        margin-top: 0px;
        /* flex-wrap: wrap; */
    }
    @media ${DEVICE.mobileL} {
        margin-top: 0px;
    }
    @media ${DEVICE.laptop} {
        margin-top: 0px;
    }
`;

const TextStyle = styled(Flex)`
    @media ${DEVICE.mobileS} {
        display: none;
    }
    @media ${DEVICE.mobileM} {
        display: none;
    }
    @media ${DEVICE.mobileL} {
        display: none;
    }
    @media ${DEVICE.tablet} {
        display: flex;
    }
    @media ${DEVICE.laptop} {
        display: flex;
        /* margin-left: 50px; */
    }
`;
