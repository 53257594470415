import React from "react";
import {
    Container,
    ContainerFluid,
    ErrorMessage,
} from "../../../../common/styles";
import {
    Button,
    Checkbox,
    Divider,
    Dropdown,
    Flex,
    Input,
    InputLabel,
    Loader,
} from "@fluentui/react-northstar";
import BackHeader from "../../../../components/shared/BackHeader";
import useLogicDelegationForm from "../../hooks/useLogicDelegationForm";
import { Controller, FormProvider } from "react-hook-form";
import {
    ToggleDelegationTitle,
    WrapperForm,
} from "./styles/DelegationForm.style";
import ShowEmployeeDelegated from "../../components/ShowEmployeeDelegated/ShowEmployeeDelegated";
import Toast from "../../../../components/Alerts";

const DelegationForm = () => {
    const {
        header,
        form,
        employees_list,
        queryDetailType,
        is_updating_delegation,
        functions,
        mutation_interrupt,
        mutation_assignation,
        today,
        toast,
        navigation,
    } = useLogicDelegationForm();

    return (
        <ContainerFluid column gap="gap.large">
            {/* sub back header */}
            <Flex fill>
                <BackHeader title={header.title} />
            </Flex>
            {/* FORM */}
            <Container>
                <Toast
                    {...toast}
                    onStatusChange={() =>
                        toast.setToast({
                            visible: false,
                            content: "",
                            level: "danger",
                        })
                    }
                />
                <WrapperForm>
                    <FormProvider {...form.methods}>
                        <form
                            onSubmit={form.methods.handleSubmit(form.onSubmit)}
                            style={{ width: "100%" }}
                        >
                            <Flex column gap="gap.small">
                                {/* toggle activation */}
                                <Flex space="between" vAlign="center">
                                    <ToggleDelegationTitle content="Délégation activée" />
                                    <Controller
                                        control={form.methods.control}
                                        name="toggle_delegation"
                                        render={({
                                            field: { onChange, value },
                                        }) => (
                                            <Checkbox
                                                toggle
                                                labelPosition="start"
                                                checked={value}
                                                onChange={(e, { checked }) => {
                                                    onChange(checked);
                                                    if (
                                                        queryDetailType.data
                                                            .records
                                                            ?.collaborator &&
                                                        !checked
                                                    ) {
                                                        // si y'a deja un délégué et qu'on decheck la checkbox
                                                        // interrupt la delegation
                                                        functions.interruptDelegation(
                                                            "from_toggle"
                                                        );
                                                    }
                                                    return;
                                                }}
                                            />
                                        )}
                                    />
                                </Flex>
                                <Divider size={1} />
                                {/* is loading or not */}
                                {queryDetailType.isLoading && <Loader />}
                                {/* if delegated => show data else show form  */}
                                {!queryDetailType.isLoading &&
                                queryDetailType.data?.success &&
                                !is_updating_delegation ? (
                                    <ShowEmployeeDelegated
                                        avatar={
                                            queryDetailType.data.records
                                                .collaborator.avatar
                                        }
                                        fullname={
                                            queryDetailType.data.records
                                                .collaborator.first_name +
                                            " " +
                                            queryDetailType.data.records
                                                .collaborator.last_name
                                        }
                                        department={
                                            queryDetailType.data.records
                                                .collaborator.department
                                        }
                                        job_title={
                                            queryDetailType.data.records
                                                .collaborator.role
                                        }
                                        start_date={
                                            queryDetailType.data.records.start
                                        }
                                        end_date={
                                            queryDetailType.data.records.end
                                        }
                                        onClickUpdate={() =>
                                            functions.updatingFormData(true)
                                        }
                                        isLoadingInterrupt={
                                            mutation_interrupt.isLoading
                                        }
                                        onClickInterrupt={
                                            functions.interruptDelegation
                                        }
                                    />
                                ) : (
                                    form.watch("toggle_delegation") && (
                                        <>
                                            {/* personne a deleguer */}
                                            <Flex column>
                                                <Flex column>
                                                    <InputLabel content="Personne à qui déléguer" />
                                                    <Controller
                                                        control={
                                                            form.methods.control
                                                        }
                                                        name="delegated_to"
                                                        rules={{
                                                            required: {
                                                                value: true,
                                                                message:
                                                                    "Ce champ est obligatoire",
                                                            },
                                                        }}
                                                        render={({
                                                            field: {
                                                                onChange,
                                                                value,
                                                                name,
                                                                ref,
                                                            },
                                                        }) => {
                                                            return (
                                                                <Dropdown
                                                                    items={
                                                                        employees_list.data
                                                                    }
                                                                    onChange={(
                                                                        e,
                                                                        i
                                                                    ) => {
                                                                        return onChange(
                                                                            i.value
                                                                        );
                                                                    }}
                                                                    name={name}
                                                                    ref={ref}
                                                                    defaultValue={
                                                                        form.defaultUserByIndex
                                                                    }
                                                                    defaultSearchQuery={
                                                                        form.defaultSearchQuery
                                                                    }
                                                                    error={
                                                                        form
                                                                            .methods
                                                                            .formState
                                                                            .errors
                                                                            .delegated_to
                                                                    }
                                                                    search
                                                                    placeholder="Choisir une personne"
                                                                    noResultsMessage="Pas de résultats."
                                                                    fluid
                                                                    checkable
                                                                />
                                                            );
                                                        }}
                                                    />
                                                </Flex>
                                                {form.methods.formState.errors
                                                    .delegated_to && (
                                                    <ErrorMessage
                                                        content={
                                                            form.methods
                                                                .formState
                                                                .errors
                                                                .delegated_to
                                                                .message
                                                        }
                                                    />
                                                )}
                                            </Flex>
                                            {/* date de début */}
                                            <Flex column>
                                                <Controller
                                                    control={
                                                        form.methods.control
                                                    }
                                                    name="start_date"
                                                    rules={{
                                                        required: {
                                                            value: true,
                                                            message:
                                                                "Ce champ est obligatoire",
                                                        },
                                                        validate: (value) => {
                                                            if (
                                                                !form.methods.getValues(
                                                                    "is_from_back"
                                                                )
                                                            ) {
                                                                return (
                                                                    value >=
                                                                        today ||
                                                                    "La date doit être supérieure ou égale à aujourd'hui"
                                                                );
                                                            }
                                                        },
                                                    }}
                                                    render={({
                                                        field: {
                                                            onChange,
                                                            value,
                                                        },
                                                    }) => (
                                                        <Input
                                                            fluid
                                                            type="date"
                                                            label="À partir de"
                                                            value={value}
                                                            min={
                                                                form.methods.getValues(
                                                                    "is_from_back"
                                                                )
                                                                    ? null
                                                                    : today
                                                            }
                                                            onChange={(
                                                                e,
                                                                i
                                                            ) => {
                                                                onChange(
                                                                    i.value
                                                                );
                                                            }}
                                                            error={
                                                                form.methods
                                                                    .formState
                                                                    .errors
                                                                    .start_date
                                                            }
                                                        />
                                                    )}
                                                />
                                                {form.methods.formState.errors
                                                    .start_date && (
                                                    <ErrorMessage
                                                        content={
                                                            form.methods
                                                                .formState
                                                                .errors
                                                                .start_date
                                                                .message
                                                        }
                                                    />
                                                )}
                                            </Flex>
                                            {/* checkbox */}
                                            <Flex>
                                                <Controller
                                                    control={
                                                        form.methods.control
                                                    }
                                                    name="checked_end_date"
                                                    render={({
                                                        field: {
                                                            onChange,
                                                            value,
                                                        },
                                                    }) => (
                                                        <Checkbox
                                                            label="Mettre une date de fin"
                                                            checked={value}
                                                            onChange={(
                                                                e,
                                                                { checked }
                                                            ) =>
                                                                onChange(
                                                                    checked
                                                                )
                                                            }
                                                        />
                                                    )}
                                                />
                                            </Flex>
                                            {/* date de fin */}
                                            {form.watch("checked_end_date") && (
                                                <Flex column>
                                                    <Controller
                                                        control={
                                                            form.methods.control
                                                        }
                                                        name="end_date"
                                                        rules={{
                                                            required: {
                                                                value: true,
                                                                message:
                                                                    "Ce champ est obligatoire",
                                                            },
                                                            validate: (
                                                                endDate
                                                            ) => {
                                                                const startDate =
                                                                    form.methods.getValues(
                                                                        "start_date"
                                                                    );
                                                                if (!startDate)
                                                                    return true;
                                                                if (
                                                                    new Date(
                                                                        endDate
                                                                    ) <
                                                                    new Date(
                                                                        startDate
                                                                    )
                                                                ) {
                                                                    return "La date de fin doit être supérieure ou égale à la date de début";
                                                                }
                                                                return true;
                                                            },
                                                        }}
                                                        render={({
                                                            field: {
                                                                onChange,
                                                                value,
                                                            },
                                                        }) => (
                                                            <Input
                                                                type="date"
                                                                label="Fini le"
                                                                onChange={(
                                                                    e,
                                                                    i
                                                                ) => {
                                                                    return onChange(
                                                                        i.value
                                                                    );
                                                                }}
                                                                error={
                                                                    form.methods
                                                                        .formState
                                                                        .errors
                                                                        .end_date
                                                                }
                                                                value={value}
                                                                fluid
                                                            />
                                                        )}
                                                    />
                                                    {form.methods.formState
                                                        .errors.end_date && (
                                                        <ErrorMessage
                                                            content={
                                                                form.methods
                                                                    .formState
                                                                    .errors
                                                                    .end_date
                                                                    .message
                                                            }
                                                        />
                                                    )}
                                                </Flex>
                                            )}
                                            {/* buttons */}
                                            <Flex
                                                column
                                                gap="gap.small"
                                                styles={{ marginTop: "40px" }}
                                            >
                                                <Button
                                                    primary
                                                    flat
                                                    fluid
                                                    content="Enregistrer les informations"
                                                    loading={
                                                        mutation_assignation.isLoading
                                                    }
                                                />
                                                <Button
                                                    fluid
                                                    flat
                                                    content="Annuler"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        if (
                                                            is_updating_delegation
                                                        ) {
                                                            functions.updatingFormData(
                                                                false
                                                            );
                                                        } else {
                                                            // retour en arriere
                                                            navigation.goBack();
                                                        }
                                                    }}
                                                />
                                            </Flex>
                                        </>
                                    )
                                )}
                            </Flex>
                        </form>
                    </FormProvider>
                </WrapperForm>
            </Container>
        </ContainerFluid>
    );
};

export default DelegationForm;
