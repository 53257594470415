import {
    Button,
    CloseIcon,
    Dialog,
    DownloadIcon,
    FilterIcon,
    Flex,
    Text,
} from "@fluentui/react-northstar";
import moment from "moment";
import React, { useState } from "react";
import { useQuery } from "react-query";
import styled from "styled-components";
import { _api_get_excel_by_month } from "../../api";
import { STATUS_MONTH } from "../../constants";
import SliderMonth from "../calendars/SliderMonth";
import FilterTimeReporting from "./FilterTimeReporting";

function Header({ month, setMonth, isLoading = false, showToast }) {
    const { isLoading: isDownloading, refetch } = useQuery(
        "MANAGER_TIME_REPORTING::excel",
        () =>
            _api_get_excel_by_month({
                month: month.month_indexed + 1,
                year: month.year,
            }),
        {
            enabled: false,
            onSuccess: (data) => {
                return console.log({ data });
            },
        }
    );

    const _incrementMonth = (e) => {
        // dispatch({ type: ACTIONS_MONTH.increment });
        let startOfMonth = moment()
            .year(month.year)
            .month(month.month_indexed + 1)
            .startOf("month")
            .isoWeekday(8);

        if (startOfMonth.date() > 7) {
            startOfMonth = startOfMonth.isoWeekday(-6);
        }
        const new_month = startOfMonth.month();
        const year = startOfMonth.year();

        setMonth((state) => {
            return {
                ...state,
                iso_week: startOfMonth.isoWeek(),
                month_indexed: new_month,
                year,
                dates: {
                    start: startOfMonth.format("DD MMMM YYYY"),
                    end: startOfMonth.isoWeekday(7).format("DD MMMM YYYY"),
                },
            };
        });
    };

    const _decrementMonth = (e) => {
        // dispatch({ type: ACTIONS_MONTH.decrement });
        let startOfMonth = moment()
            .year(month.year)
            .month(month.month_indexed - 1)
            .startOf("month")
            .isoWeekday(8);

        if (startOfMonth.date() > 7) {
            startOfMonth = startOfMonth.isoWeekday(-6);
        }
        const new_month = startOfMonth.month();
        const year = startOfMonth.year();

        setMonth((state) => {
            return {
                ...state,
                iso_week: startOfMonth.isoWeek(),
                month_indexed: new_month,
                year,
                dates: {
                    start: startOfMonth.format("DD MMMM YYYY"),
                    end: startOfMonth.isoWeekday(7).format("DD MMMM YYYY"),
                },
            };
        });
    };

    const _download_excel = (e) => {
        return refetch();
    };

    return (
        <Container space="between">
            <SliderMonth
                month_name={moment()
                    .set("month", month?.month_indexed)
                    .format("MMMM")}
                onDecrement={_decrementMonth}
                onIncrement={_incrementMonth}
                month={month}
                isLoading={isLoading}
                badge_status={
                    month.month_indexed === moment().month()
                        ? STATUS_MONTH.in_progress.type
                        : STATUS_MONTH.default.type
                }
            />
            <Flex gap="gap.large">
                <Button
                    text
                    iconPosition="after"
                    icon={<DownloadIcon outline />}
                    content="Télécharger le tableau pour Excel"
                    onClick={_download_excel}
                    loading={isDownloading}
                />
            </Flex>
        </Container>
    );
}

export default Header;

const Container = styled(Flex)``;

const ButtonLink = styled(Text)`
    text-decoration: underline;
    color: #5b5fc7;
`;
