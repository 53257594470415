import { Flex, Text, Tooltip } from "@fluentui/react-northstar";
import React from "react";
import { CommentRegular } from "@fluentui/react-icons";
import styled from "styled-components";
import StripedTypeLeaveWeek from "../StripedTypeLeaveWeek";
import { CustomBadge } from "../../../../components/Badge";

const renderSlot = ({ slot, day_hours_count }) => {
    return (
        <Flex fill>
            {!slot.hours_count ? (
                <Flex styles={{ padding: "2px 4px" }} fill>
                    <StripedTypeLeaveWeek
                        background_color={slot?.leave_color + "50"}
                        leave_name={slot?.leave_name}
                        striped_color={slot?.leave_color}
                        hours={slot?.hours_count}
                    />
                </Flex>
            ) : (
                <Flex fill hAlign="center" vAlign="center">
                    {slot?.shifts?.length ? (
                        <Tooltip
                            onClick={() => console.log({ slot })}
                            position="after"
                            align="top"
                            subtle={false}
                            pointing
                            content={
                                <Flex column gap="gap.medium">
                                    <Text content="Créneaux du jour" />
                                    <Flex column gap="gap.smaller">
                                        {slot?.shifts?.map((el) => {
                                            const start_time_formatted =
                                                el?.start_time
                                                    ?.replaceAll(":", "h")
                                                    .slice(0, -3);
                                            const end_time_formatted =
                                                el?.end_time
                                                    ?.replaceAll(":", "h")
                                                    .slice(0, -3);
                                            return (
                                                <Flex>
                                                    <Text
                                                        content={`${start_time_formatted} - ${end_time_formatted}`}
                                                    />
                                                </Flex>
                                            );
                                        })}
                                    </Flex>
                                </Flex>
                            }
                            trigger={
                                <CellText content={`${slot?.hours_count} h`} />
                            }
                        />
                    ) : (
                        <CellText content={`${day_hours_count} h`} />
                    )}
                </Flex>
            )}
        </Flex>
    );
};

const Cell = ({ el: day }) => {
    const isNotWorking = day?.type?.value === 3;
    const isWorking = day?.type?.value === 0;
    const isHoliday = day?.type?.value === 1;
    const isWeeklyRest = day?.type?.value === 4;
    const isLeave = day?.type?.value === 2;
    const comment = day?.comment;
    const slots = day?.slots;
    const slots_len = slots?.length ?? 0;

    return (
        <OneCell vAlign="end" hAlign="center" space="between" column>
            <Flex fill hAlign="end">
                {comment ? (
                    <Tooltip
                        trigger={
                            <CustomBadge
                                color="small"
                                size="smaller"
                                bg="#F0F0F0"
                                styles={{ margin: 0 }}
                                label={
                                    <Flex vAlign="center">
                                        <Text
                                            styles={{
                                                marginRight: "5px",
                                                color: "black",
                                            }}
                                            content={"1"}
                                        />
                                        <CommentRegular color="black" />
                                    </Flex>
                                }
                            />
                        }
                        content={
                            <Flex column gap="gap.medium">
                                <Flex column gap="gap.medium">
                                    <Text content="Saisie modifiée :" />
                                    {slots?.map((el) => {
                                        return el?.shifts.map((shift) => {
                                            const start_time_formatted =
                                                shift?.start_time
                                                    ?.replaceAll(":", "h")
                                                    .slice(0, -3);
                                            const end_time_formatted =
                                                shift?.end_time
                                                    ?.replaceAll(":", "h")
                                                    .slice(0, -3);
                                            return (
                                                <Flex>
                                                    <Text
                                                        content={`${start_time_formatted} - ${end_time_formatted}`}
                                                    />
                                                </Flex>
                                            );
                                        });
                                    })}
                                </Flex>
                                <Flex column gap="gap.medium">
                                    <Text content="Commentaire :" />
                                    <Text content={comment} />
                                </Flex>
                            </Flex>
                        }
                        subtle={false}
                        pointing
                        position="after"
                        align="top"
                    />
                ) : (
                    <Flex styles={{ height: "24px" }}></Flex>
                )}
            </Flex>
            <Flex hAlign="center" vAlign="center" fill>
                {isNotWorking && (
                    <Flex fill hAlign="center" styles={{ padding: "8px" }}>
                        <CellText content="-" />
                    </Flex>
                )}
                {isHoliday && (
                    <Flex
                        fill
                        hAlign="center"
                        styles={{
                            padding: "8px",
                            backgroundColor: "#FBF6D9",
                            outline: "1px solid #FBF6D9",
                            borderRadius: "2px ",
                        }}
                    >
                        <CellText content="Férié" />
                    </Flex>
                )}
                {isWeeklyRest && slots_len === 0 && (
                    <Flex fill hAlign="center" styles={{ padding: "8px" }}>
                        <CellText content="-" />
                    </Flex>
                )}
                {isWeeklyRest &&
                    slots_len > 0 &&
                    day?.slots?.map((slot) => {
                        return renderSlot({
                            slot,
                            day_hours_count: day?.hours_count,
                        });
                    })}
                {isLeave && (
                    <StripedTypeLeaveWeek
                        background_color={day.type?.leave_color + "50"}
                        leave_name={day.type?.leave_name}
                        striped_color={day.type?.leave_color}
                        // hours={8}
                    />
                )}

                {isWorking &&
                    day?.slots?.map((slot) => {
                        return renderSlot({
                            slot,
                            day_hours_count: day?.hours_count,
                        });
                    })}

                {isWorking && day?.slots?.length === 0 && (
                    <Flex vAlign="center" hAlign="center" fill>
                        {comment ? (
                            <Tooltip
                                position="after"
                                align="top"
                                subtle={false}
                                pointing
                                content={
                                    <Flex column gap="gap.medium">
                                        <Flex column gap="gap.smaller">
                                            <Flex column gap="gap.medium">
                                                <Text
                                                    content={`Commentaire :`}
                                                />
                                                <Text content={comment} />
                                            </Flex>
                                        </Flex>
                                    </Flex>
                                }
                                trigger={<CellText content={`0 h`} />}
                            />
                        ) : (
                            <CellText content={`0 h`} />
                        )}
                    </Flex>
                )}

                {isWorking && day?.hours_count === null && (
                    <Flex vAlign="center" hAlign="center" fill>
                        {comment ? (
                            <Tooltip
                                position="after"
                                align="top"
                                subtle={false}
                                pointing
                                content={
                                    <Flex column gap="gap.medium">
                                        <Flex column gap="gap.smaller">
                                            <Flex column gap="gap.medium">
                                                <Text
                                                    content={`Commentaire :`}
                                                />
                                                <Text content={comment} />
                                            </Flex>
                                        </Flex>
                                    </Flex>
                                }
                                trigger={<CellText content={`0 h`} />}
                            />
                        ) : (
                            <CellText content={`0 h`} />
                        )}
                    </Flex>
                )}
            </Flex>
        </OneCell>
    );
};

export default Cell;

const OneCell = styled(Flex)`
    width: 120px;
    /* gap: 8px; */
`;

export const CellText = styled(Text)`
    color: #242424;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
`;
