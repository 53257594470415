import React from "react";
import { Container, ContainerIcon, Overlay } from "./index.style";
import { TimerRegular } from "@fluentui/react-icons";
import useLogicTimeClock from "./hooks/useLogicTimeClock";
import MenuTimeClock from "./components/MenuTimeClock";
import Timer from "./services/Timer/Timer";
import { useAuthDetailStore, useAuthStore } from "../../stores/useAuthStore";
import { isMobile } from "react-device-detect";

const TimeClock = () => {
    const infos = useAuthStore((state) => state.infos);
    const detail = useAuthDetailStore((state) => state.detail);

    const { timeclock, queryTimeclock, mutations, onClickTimeClockIcon } =
        useLogicTimeClock();

    const is_started_today = queryTimeclock.data?.start_time;
    const is_stopped = queryTimeclock.data?.is_stopped;
    const is_paused = queryTimeclock.data?.is_paused;

    // si non connecté, ne rien afficher
    if (!infos || !detail?.features?.time_reporting?.badge_activated) {
        return null;
    }

    return (
        <>
            {timeclock.open_menu && (
                <Overlay onClick={() => onClickTimeClockIcon()} />
            )}
            <Container column gap="gap.small" hAlign="end">
                {(!timeclock.open_menu || !is_started_today || is_stopped) && (
                    <ContainerIcon
                        hAlign="center"
                        vAlign="center"
                        $idle={is_started_today && !is_stopped && !is_paused}
                        $paused={is_paused}
                        onClick={onClickTimeClockIcon}
                    >
                        <TimerRegular
                            color={is_paused ? "#9299F7" : "white"}
                            fontSize={isMobile ? "26px" : "38px"}
                            style={{
                                strokeWidth: "0.6px",
                                stroke: is_paused ? "#9299F7" : "white",
                            }}
                        />
                    </ContainerIcon>
                )}
                {is_started_today && timeclock.open_menu && !is_stopped && (
                    <Timer
                        onClick={onClickTimeClockIcon}
                        timer={timeclock.format_timer_count}
                        $isStopped={is_stopped}
                        $isPaused={is_paused}
                    />
                )}
                {/* le menu */}
                {timeclock.open_menu && (
                    <MenuTimeClock
                        isStartedToday={is_started_today}
                        isStopped={is_stopped}
                        isPaused={is_paused}
                        isLoading={{
                            pause: mutations.pause.isLoading,
                            start: mutations.start.isLoading,
                            stop: mutations.stop.isLoading,
                        }}
                        onClickStart={() => mutations.start.mutate()}
                        onClickPause={() => mutations.pause.mutate()}
                        onClickStop={() => mutations.stop.mutate()}
                    />
                )}
            </Container>
        </>
    );
};

export default TimeClock;
